<template>
  <LostPassword :cardHeading="$t('lostPassword.cardHeading')">
    <Form :formFields="formFields" @on-submit="onSubmit" :stacked="true">
      <template v-slot:formfooter>
        <slot>
          <div class="row mt-0">
            <div class="col-md-12 pt-0">
              <p class="text-danger" v-if="getErrors.resetPass.length">
                {{ getErrors.resetPass }}
              </p>

              <div class="alert alert-success" role="alert" v-if="success">
                {{ success }}
              </div>


              <button class="btn btn-primary btn-block mt-2" :disabled="getLoadings.resetPass">
                {{ $t('lostPassword.setNewPassword') }}
                <b-spinner v-if="getLoadings.resetPass" small label="Spinning" variant="white"></b-spinner>
              </button>
            </div>
          </div>
        </slot>
      </template>
    </Form>
  </LostPassword>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LostPassword from "../components/LostPassword/LostPassword.vue";
import Form from "../components/ui/form/Form.vue";

export default {
  components: { LostPassword, Form },
  data() {
    return {
      success: ''
    }
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    formFields() {
      return [
        {
          component: "TextInput",
          name: "password",
          type: "password",
          placeholder: this.$t("password"),
          label: this.$t("password"),
          rules: "required|min:8|max:20",
          colClasses: "pb-0"
        },
        {
          component: "TextInput",
          name: "password_confirmation",
          type: "password",
          placeholder: this.$t("lostPassword.passwordRepeatedPlaceholder"),
          label: this.$t("lostPassword.passwordRepeatedPlaceholder"),
          rules: "required|confirmed:password",
          colClasses: "pt-0"
        },
      ];
    },
  },
  methods: {
    ...mapActions("auth", ["resetPass"]),
    async onSubmit(data) {
      const token = this.$route.params.token;
      const type = this.$route.params.type;
      let response = await this.resetPass({
        body: { token, password: data.password, password_confirmation: data.password_confirmation },
      });
      if (response) {
        this.success = response.message;
        setTimeout(() => {
          this.$router.push(`/${type}/login`);
        }, 2000)
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>