import { render, staticRenderFns } from "./LostPasswordReset.vue?vue&type=template&id=53a4a46c&scoped=true&"
import script from "./LostPasswordReset.vue?vue&type=script&lang=js&"
export * from "./LostPasswordReset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53a4a46c",
  null
  
)

export default component.exports